<template>
  <b-modal
    ref="bottling-return-modal"
    centered
    hide-footer
    size="lg"
    :title="title"
  >
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="6">
            <inline-input
              :id="controls.finished_product.id"
              :value="data.finished_product"
              label="Finished Product"
              :readonly="true"
          /></b-col>
          <b-col lg="6">
            <inline-input
              :id="controls.alcohol_percentage.id"
              :value="data.alcohol_percentage"
              label="Alcohol %"
              :readonly="true"
          /></b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <inline-input
              :id="controls.batch_number.id"
              :value="data.batch_number"
              label="Batch Number"
              :mode="$constants.FORM_MODE.EDIT"
              :readonly="isReadonly"
              :required="true"
              @changed="onBatchNumberUpdate"
            />
          </b-col>
          <b-col lg="6">
            <inline-input
              :id="controls.amount_bottles.id"
              :value="data.amount_bottles"
              label="Bottles"
              :mode="$constants.FORM_MODE.EDIT"
              :readonly="isReadonly"
              :required="true"
              @keyup="onBottlesAmountChange"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3">
            <inline-input
              :id="controls.bottle_size.id"
              :value="data.bottle_size"
              label="Bottle Size"
              :readonly="true"
            />
          </b-col>
          <b-col lg="3">
            <inline-input
              :id="controls.total_amount.id"
              :value="data.total_amount"
              label="Total Amount (L)"
              :readonly="true"
            />
          </b-col>

          <b-col lg="3">
            <inline-select
              :id="controls.case_size.id"
              :value="data.case_size"
              label="Case Size"
              :readonly="isReadonly"
              :options="controls.case_size.options"
              :taggable="true"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              @changed="onCaseSizeSelect"
            />
          </b-col>

          <b-col lg="3">
            <inline-input
              :id="controls.amount_cases.id"
              :value="data.amount_cases"
              label="Cases"
              :mode="$constants.FORM_MODE.EDIT"
              :readonly="true"
              :required="true"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <inline-select
              :id="controls.warehouse.id"
              :value="data.warehouse"
              label="Warehouse"
              :readonly="isReadonly"
              :options="controls.warehouse.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              @changed="onWarehouseChange"
            />
          </b-col>
          <b-col lg="6">
            <inline-date-picker
              :id="controls.date_returned.id"
              :value-single="data.date_returned"
              label="Date Returned"
              :readonly="isReadonly"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              @changed="onDateReturnedChange"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6">
            <inline-select
              :id="controls.returned_by.id"
              :value="data.returned_by"
              label="Returned by"
              :readonly="isReadonly"
              :options="controls.returned_by.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              :required="true"
              @changed="onReturnedByChange"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <hr />
    <div class="form-row d-flex  justify-content-end">
      <b-button
        v-if="!isReadonly"
        variant="warning"
        class="m-1"
        @click="returnBottling()"
      >
        <b-spinner v-if="isLoading" small type="grow" />
        <font-awesome-icon v-if="!isLoading" icon="undo" /> Return
      </b-button>
      <b-button variant="outline-dark" class="m-1" @click="hide()">
        <font-awesome-icon icon="times" /> Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import dictionaryServices from "@/services/dictionary.service";

import bottlingService from "@/services/bottling.service";
import userManagementService from "@/services/user-management.service";

import { mapState } from "vuex";
export default {
  props: {},
  name: "ReturnBottlingModal",
  data: function() {
    return {
      isLoading: false,

      title: "Bottling return",
      controls: {
        finished_product: {
          id: "input:finished_product"
        },

        batch_number: {
          id: "input:batch_number"
        },
        alcohol_percentage: {
          id: "input:alcohol_percentage"
        },
        bottle_size: {
          id: "input:bottle_size"
        },
        total_amount: {
          id: "input:total_amount"
        },

        amount_bottles: {
          id: "input:amount_bottles"
        },
        amount_cases: {
          id: "input:amount_cases"
        },
        warehouse: {
          id: "select:warehouse",
          options: []
        },
        returned_by: {
          id: "select:returned_by",
          options: []
        },
        date_returned: {
          id: "datepicker:date_returned"
        },
        case_size: {
          id: "select:case_size",
          options: []
        }
      },
      data: {
        returned_bottling_number: undefined,
        finished_product: "",
        batch_number: "",
        alcohol_percentage: "",
        bottle_size: "",
        amount_bottles: 0,
        amount_cases: 0,
        warehouse: {},
        date_returned: "",
        returned_by: {},
        bottle_size_id: "",
        amount_bottled_pg: 0,
        total_amount: 0
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    isReadonly() {
      return this.data.returned_bottling_number !== undefined;
    }
  },
  mounted() {},
  methods: {
    show(payload) {
      this.data.returned_bottling_number = undefined;
      this.data.finished_product_id = payload["Finished Product ID"];
      this.data.finished_product = payload["Finished Product"];
      //this.data.batch_number = payload["Batch Number"];
      this.data.alcohol_percentage = payload["Alcohol %"];
      this.data.bottle_size = payload["Bottle Size"];
      this.data.bottle_size_id = payload["Bottle Size ID"];
      this.data.bottling_number = payload["ID"];
      this.data.organic_status_id = payload["Organic Status ID"];

      this.data.warehouse = {
        id: payload["Warehouse ID"],
        label: payload["Warehouse"]
      };

      this.data.date_returned = this.$nowClientAsString;
      this.data.amount_bottles = 0;
      this.data.amount_cases = 0;
      this.data.total_amount = 0;
      this.data.batch_number = "";

      this.$refs["bottling-return-modal"].show();

      dictionaryServices.fetchWarehouses().then(response => {
        this.controls.warehouse.options = this.$helpers.mapDictionaryOptions(
          response
        );
      });

      dictionaryServices.fetchCaseSizes().then(response => {
        this.controls.case_size.options = response.map(u => ({
          id: u.id,
          label: u.name,
          bottles: u.bottles
        }));

        let caseSize = this.controls.case_size.options.find(
          i => i.bottles == payload["Case Size"]
        );
        if (!caseSize) {
          caseSize = {
            id: this.$helpers.uuidv4(),
            label: payload["Case Size"],
            bottles: +payload["Case Size"]
          };
          this.controls.case_size.options.push(caseSize);
        }

        this.data.case_size = caseSize;
      });

      userManagementService.fetchActiveUsers().then(response => {
        this.controls.returned_by.options = response;
        /*
        this.controls.returned_by.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
        */

        this.data.returned_by = this.controls.returned_by.options.find(
          i => i.id == this.profile.data.id
        );
      });
    },
    hide() {
      this.$refs["bottling-return-modal"].hide();
    },
    async returnBottling() {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return false;
      }

      let result = "";
      try {
        this.isLoading = true;
        result = await bottlingService.returnBottling(this.data);

        this.data.returned_bottling_number = result.id;

        this.$form.makeToastInfo(result.message);

        this.$emit("success");
      } catch (err) {
        this.$form.makeToastError(err);
      } finally {
        this.isLoading = false;
      }
    },
    onReturnedByChange(id, val) {
      this.data.returned_by = val;
    },
    onDateReturnedChange({ valueSingleAsString }) {
      this.data.date_returned = valueSingleAsString;
    },
    onWarehouseChange(id, val) {
      this.data.warehouse = val;
    },
    onBatchNumberUpdate(field, value) {
      this.data.batch_number = value;
    },
    onCaseSizeSelect(field, value) {
      //if new value then bottles = id
      if (!value.bottles) value.bottles = +value.id;
      this.data.case_size = value;

      this.updateCalculations();
    },
    onBottlesAmountChange(e) {
      this.data.amount_bottles = +e.val;

      this.updateCalculations();
    },
    updateCalculations() {
      let bottleSizeValue = this.data.bottle_size / 1000;
      //calculate cases
      let caseBottles = this.data.case_size.bottles;

      this.data.amount_cases = Math.floor(
        this.data.amount_bottles / caseBottles
      );

      this.data.amount_cases = this.data.case_size
        ? this.data.amount_bottles / this.data.case_size.bottles
        : 1;

      this.data.amount_bottled_pg =
        (((this.data.amount_bottles * bottleSizeValue) / 3.7854118) *
          this.data.alcohol_percentage) /
        100 /
        0.5;

      this.data.total_amount = this.data.amount_bottles * bottleSizeValue;

      this.data.total_amount = this.$helpers.round(this.data.total_amount, 4);
      this.data.amount_cases = this.$helpers.round(this.data.amount_cases, 4);
      this.data.amount_bottled_pg = this.$helpers.round(
        this.data.amount_bottled_pg,
        4
      );
    }
  }
};
</script>

<style></style>
