var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "bottling-return-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.finished_product.id,
      "value": _vm.data.finished_product,
      "label": "Finished Product",
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.alcohol_percentage.id,
      "value": _vm.data.alcohol_percentage,
      "label": "Alcohol %",
      "readonly": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.batch_number.id,
      "value": _vm.data.batch_number,
      "label": "Batch Number",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "readonly": _vm.isReadonly,
      "required": true
    },
    on: {
      "changed": _vm.onBatchNumberUpdate
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_bottles.id,
      "value": _vm.data.amount_bottles,
      "label": "Bottles",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "readonly": _vm.isReadonly,
      "required": true
    },
    on: {
      "keyup": _vm.onBottlesAmountChange
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.bottle_size.id,
      "value": _vm.data.bottle_size,
      "label": "Bottle Size",
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.total_amount.id,
      "value": _vm.data.total_amount,
      "label": "Total Amount (L)",
      "readonly": true
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.case_size.id,
      "value": _vm.data.case_size,
      "label": "Case Size",
      "readonly": _vm.isReadonly,
      "options": _vm.controls.case_size.options,
      "taggable": true,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onCaseSizeSelect
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.controls.amount_cases.id,
      "value": _vm.data.amount_cases,
      "label": "Cases",
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "readonly": true,
      "required": true
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.warehouse.id,
      "value": _vm.data.warehouse,
      "label": "Warehouse",
      "readonly": _vm.isReadonly,
      "options": _vm.controls.warehouse.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onWarehouseChange
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.controls.date_returned.id,
      "value-single": _vm.data.date_returned,
      "label": "Date Returned",
      "readonly": _vm.isReadonly,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onDateReturnedChange
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('inline-select', {
    attrs: {
      "id": _vm.controls.returned_by.id,
      "value": _vm.data.returned_by,
      "label": "Returned by",
      "readonly": _vm.isReadonly,
      "options": _vm.controls.returned_by.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onReturnedByChange
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [!_vm.isReadonly ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.returnBottling();
      }
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "undo"
    }
  }) : _vm._e(), _vm._v(" Return ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.hide();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }