<template>
  <b-modal
    ref="bottling-transfer-modal"
    centered
    hide-footer
    size="lg"
    :title="title"
  >
    <b-card>
      <b-card-body>
        <b-row>
          <b-col lg="6">
            <inline-switch
              id="invoice-transfer"
              v-model="isInvoiceTransfer"
              :hide-label="false"
              label="Invoice transfer"
            />
          </b-col>
        </b-row>

        <template v-if="!isInvoiceTransfer">
          <b-row>
            <b-col lg="6">
              <inline-input
                :id="controls.finished_product.id"
                :value="data.finished_product"
                label="Finished Product"
                :readonly="true"
            /></b-col>
            <b-col lg="6">
              <inline-input
                :id="controls.alcohol_percentage.id"
                :value="data.alcohol_percentage"
                label="Alcohol %"
                :readonly="true"
            /></b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-input
                :id="controls.batch_number.id"
                :value="data.batch_number"
                label="Batch Number"
                :readonly="true"
            /></b-col>

            <b-col lg="6">
              <inline-select
                :id="controls.warehouse.id"
                v-model="data.warehouse"
                label="Warehouse"
                :readonly="isReadonly"
                :options="controls.warehouse.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-input
                :id="controls.amount_bottles.id"
                :value="data.amount_bottles"
                label="Bottles"
                :mode="$constants.FORM_MODE.EDIT"
                :readonly="isReadonly"
                :required="true"
                @keyup="onBottlesAmountChange"
              />
            </b-col>
            <b-col lg="6">
              <inline-input
                :id="controls.bottles_available.id"
                :value="data.bottles_available"
                label="Bottles Available"
                :mode="$constants.FORM_MODE.EDIT"
                :readonly="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-date-picker
                :id="controls.transfer_date.id"
                :value-single="data.transfer_date"
                label="Transfer Date"
                :readonly="isReadonly"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
                @changed="onTransferDateChange"
              />
            </b-col>
            <b-col lg="6">
              <inline-select
                :id="controls.transfered_by.id"
                v-model="data.transfered_by"
                label="Transfered by"
                :readonly="isReadonly"
                :options="controls.transfered_by.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col lg="3">
              <inline-input
                :id="controls.bottle_size.id"
                :value="data.bottle_size"
                label="Bottle Size"
                :readonly="true"
              />
            </b-col>
            <b-col lg="3">
              <inline-input
                :id="controls.total_amount.id"
                :value="data.total_amount"
                label="Total Amount (L)"
                :readonly="true"
              />
            </b-col>

            <b-col lg="3">
              <inline-select
                :id="controls.case_size.id"
                :value="data.case_size"
                label="Case Size"
                :readonly="true"
                :options="controls.case_size.options"
                :taggable="true"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
                @changed="onCaseSizeSelect"
              />
            </b-col>

            <b-col lg="3">
              <inline-input
                :id="controls.amount_cases.id"
                :value="data.amount_cases"
                label="Cases"
                :mode="$constants.FORM_MODE.EDIT"
                :readonly="true"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row> </b-row>
        </template>

        <template v-if="isInvoiceTransfer">
          <b-row>
            <b-col lg="6">
              <inline-select
                :id="controls.invoice.id"
                v-model="data.invoice"
                label="Invoice"
                :readonly="isReadonly"
                :options="controls.invoice.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
              />
            </b-col>
            <b-col lg="6">
              <inline-select
                :id="controls.warehouse.id"
                v-model="data.warehouse"
                label="Warehouse"
                :readonly="isReadonly"
                :options="controls.warehouse.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <inline-date-picker
                :id="controls.transfer_date.id"
                :value-single="data.transfer_date"
                label="Transfer Date"
                :readonly="isReadonly"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
                @changed="onTransferDateChange"
              />
            </b-col>
            <b-col lg="6">
              <inline-select
                :id="controls.transfered_by.id"
                v-model="data.transfered_by"
                label="Transfered by"
                :readonly="isReadonly"
                :options="controls.transfered_by.options"
                :allow-empty="false"
                :multiple="false"
                :mode="$constants.FORM_MODE.EDIT"
                :required="true"
              />
            </b-col>
          </b-row>
        </template>
      </b-card-body>
    </b-card>
    <hr />
    <div class="form-row d-flex  justify-content-end">
      <b-button
        v-if="!isReadonly"
        variant="warning"
        class="m-1"
        @click="transferBottling()"
      >
        <b-spinner v-if="isLoading" small type="grow" />
        <font-awesome-icon v-if="!isLoading" icon="truck" /> Transfer
      </b-button>

      <b-button variant="outline-dark" class="m-1" @click="hide()">
        <font-awesome-icon icon="times" /> Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import dictionaryServices from "@/services/dictionary.service";
import bottlingService from "@/services/bottling.service";
import userManagementService from "@/services/user-management.service";

import { mapState } from "vuex";
export default {
  props: {},
  name: "TransferBottlingModal",
  data: function() {
    return {
      isLoading: false,
      isInvoiceTransfer: false,
      title: "Bottling transfer",
      controls: {
        finished_product: {
          id: "input:finished_product"
        },

        batch_number: {
          id: "input:batch_number"
        },
        alcohol_percentage: {
          id: "input:alcohol_percentage"
        },
        bottle_size: {
          id: "input:bottle_size"
        },
        total_amount: {
          id: "input:total_amount"
        },
        bottles_available: {
          id: "input:bottles_available"
        },
        amount_bottles: {
          id: "input:amount_bottles"
        },
        amount_cases: {
          id: "input:amount_cases"
        },
        warehouse: {
          id: "select:warehouse",
          options: []
        },
        transfered_by: {
          id: "select:transfered_by",
          options: []
        },
        transfer_date: {
          id: "datepicker:transfer_date"
        },
        case_size: {
          id: "select:case_size",
          options: []
        },
        invoice: {
          id: "select:invoice",
          options: []
        }
      },
      data: {
        returned_bottling_number: undefined,
        finished_product: "",
        finished_product_number: "",
        batch_number: "",
        alcohol_percentage: "",
        bottle_size: "",
        amount_used: 0,
        amount_bottles: 0,
        amount_cases: 0,
        warehouse: {},
        transfer_date: "",
        transfered_by: {},
        bottle_size_id: "",
        amount_bottled_pg: 0,
        total_amount: 0,
        total_bottles_available: 0,
        invoice: {}
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    }),
    isReadonly() {
      return this.data.returned_bottling_number !== undefined;
    }
  },
  mounted() {},
  methods: {
    show(payload) {
      this.data.returned_bottling_number = undefined;
      this.data.finished_product_id = payload["Finished Product ID"];
      this.data.finished_product = payload["Finished Product"];
      this.data.finished_product_number = payload["Finished Product Number"];
      this.data.amount_used = payload["Amount Used"];
      this.data.batch_number = payload["Batch Number"];
      this.data.alcohol_percentage = payload["Alcohol %"];
      this.data.bottle_size = payload["Bottle Size"];
      this.data.bottle_size_id = payload["Bottle Size ID"];
      this.data.bottling_number = payload["ID"];
      this.data.organic_status_id = payload["Organic Status ID"];

      this.data.total_bottles_available = payload["Bottles Remaining"];

      this.data.warehouse = {
        id: payload["Warehouse ID"],
        label: payload["Warehouse"]
      };

      this.data.transfer_date = this.$nowClientAsString;
      this.data.amount_bottles = 0;

      this.data.amount_cases = 0;
      this.data.total_amount = 0;

      this.data.bottles_available = this.data.total_bottles_available;

      this.$refs["bottling-transfer-modal"].show();

      dictionaryServices.fetchWarehouses().then(response => {
        this.controls.warehouse.options = this.$helpers.mapDictionaryOptions(
          response
        );
      });
      dictionaryServices.fetchWarehouseInvoices().then(response => {
        this.controls.invoice.options = response.map(u => ({
          id: u["ID"],
          label: `${u["ID"]}, ${u["Invoice Date"]}, ${u["Account Name"]}, $${u["Total"]} `
        }));
      });

      dictionaryServices.fetchCaseSizes().then(response => {
        this.controls.case_size.options = response.map(u => ({
          id: u.id,
          label: u.name,
          bottles: u.bottles
        }));

        let caseSize = this.controls.case_size.options.find(
          i => i.bottles == payload["Case Size"]
        );
        if (!caseSize) {
          caseSize = {
            id: this.$helpers.uuidv4(),
            label: payload["Case Size"],
            bottles: +payload["Case Size"]
          };
          this.controls.case_size.options.push(caseSize);
        }

        this.data.case_size = caseSize;
      });

      userManagementService.fetchActiveUsers().then(response => {
        this.controls.transfered_by.options = response;
        /*
        this.controls.transfered_by.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));*/

        this.data.transfered_by = this.controls.transfered_by.options.find(
          i => i.id == this.profile.data.id
        );
      });
    },
    hide() {
      this.$refs["bottling-transfer-modal"].hide();
    },

    onTransferDateChange({ valueSingleAsString }) {
      this.data.transfer_date = valueSingleAsString;
    },

    async transferBottling() {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return false;
      }

      let result = "";
      try {
        this.isLoading = true;
        result = await bottlingService.transferBottling(this.data);

        this.data.returned_bottling_number = result.id;

        if (this.isInvoiceTransfer && result.status == 200) {
          this.data.invoice = undefined;
        }

        this.$form.makeToastInfo(result.message);

        this.$emit("success");
      } catch (err) {
        this.$form.makeToastError(err);
      } finally {
        this.isLoading = false;
      }
    },
    onCaseSizeSelect(field, value) {
      //if new value then bottles = id
      if (!value.bottles) value.bottles = +value.id;
      this.data.case_size = value;

      this.updateCalculations();
    },
    onBottlesAmountChange(e) {
      let newValue = Number.isNaN(parseInt(e.val)) ? 0 : parseInt(e.val);

      if (newValue > this.data.bottles_available) {
        //newValue = this.data.bottles_available;
      }

      this.data.amount_bottles = newValue;

      this.updateCalculations();
    },
    updateCalculations() {
      let bottleSizeValue = this.data.bottle_size / 1000;
      //calculate cases
      let caseBottles = this.data.case_size.bottles;

      this.data.bottles_available =
        this.data.total_bottles_available - this.data.amount_bottles;

      this.data.amount_cases = Math.floor(
        this.data.amount_bottles / caseBottles
      );

      this.data.amount_cases = this.data.case_size
        ? this.data.amount_bottles / this.data.case_size.bottles
        : 1;

      this.data.amount_bottled_pg =
        (((this.data.amount_bottles * bottleSizeValue) / 3.7854118) *
          this.data.alcohol_percentage) /
        100 /
        0.5;

      this.data.total_amount = this.data.amount_bottles * bottleSizeValue;

      this.data.total_amount = this.$helpers.round(this.data.total_amount, 4);
      this.data.amount_cases = this.$helpers.round(this.data.amount_cases, 4);
      this.data.amount_bottled_pg = this.$helpers.round(
        this.data.amount_bottled_pg,
        4
      );
    }
  },
  watch: {
    isInvoiceTransfer(newVal) {
      if (!newVal) this.data.invoice = undefined;
    }
  }
};
</script>

<style></style>
