<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(dataset)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="afterFilter">
        <b-row>
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add bottling"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-if="+props.row['Bottles Remaining'] > 0"
            class="btn btn-warning btn-sm"
            @click="showTransferModal(props.row)"
            title="Transfer bottling"
          >
            <strong>MV</strong>
          </button>

          <button
            v-if="!props.row['Return']"
            class="btn btn-warning btn-sm"
            @click="showReturnModal(props.row)"
            title="Bottling return "
          >
            <strong>RT</strong>
          </button>

          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
            title="View"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
            title="Edit"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete"
          >
            <font-awesome-icon icon="trash" />
          </button>
        </div>
      </div>
    </table-custom>

    <BottlingReturnModal
      ref="bottling-return-modal"
      @success="onBottlingReturnSuccess"
    ></BottlingReturnModal>
    <BottlingTransferModal
      ref="bottling-transfer-modal"
      @success="onBottlingTransferSuccess"
    ></BottlingTransferModal>
  </div>
</template>

<script>
import BottlingReturnModal from "./BottlingReturnModal";
import BottlingTransferModal from "./BottlingTransferModal";

export default {
  props: {
    alcoholFinishingId: {
      type: [String, Number],
      default: ""
    },
    barrelNumber: {
      type: [String, Number],
      default: ""
    }
  },
  name: "BottlingTable",
  components: {
    BottlingReturnModal,
    BottlingTransferModal
  },
  data: function() {
    return {
      dataset: {
        name: "bottling"
      },
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "ID",
            "Related Bottling",
            "Finished Product Number",
            "Finished Product",
            "Barrel Number",
            "Bottles Remaining",

            "Batch Number",
            "Bottle Date",
            "Bottle Size",
            "Return",
            "Amount Used",
            "Amount Bottles",
            "Amount Cases",
            "PG",
            "WG",
            "Warehouse",
            "Created By",
            "Created",
            "Actions"
          ],
          filterable: [
            "ID",
            "Related Bottling",
            "Finished Product Number",
            "Finished Product",
            "Barrel Number",
            "Bottles Remaining",
            "Batch Number",

            "Bottle Date",
            "Bottle Size",
            "Return",
            "Amount Used",
            "Amount Bottles",
            "Amount Cases",
            "PG",
            "WG",
            "Warehouse",
            "Created By",
            "Created"
          ],
          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showCustomActions: true
        }
      },
      selectedFilters: {}
    };
  },
  computed: {},
  mounted() {
    if (this.alcoholFinishingId)
      this.getData({ alcohol_finishing_id: this.alcoholFinishingId });
    if (this.barrelNumber) this.getData({ barrel_number: this.barrelNumber });
  },
  methods: {
    onFilter() {},

    async getData(payload) {
      let self = this;

      this.selectedFilters = payload || {};

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        if (this.alcoholFinishingId)
          response = await this.$api.post(
            `alcohol-finishing/${this.alcoholFinishingId}/bottling`,
            payload
          );
        else response = await this.$api.post("bottling", payload);

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    addItem: function() {
      this.$router.push({
        name: "Bottling submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Bottling submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Bottling submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },

    deleteItem: async function(payload) {
      let item = this.dataTable.dataSet.find(item => item.ID === payload.ID);

      let confirm = await this.$form.showConfirmation(
        `Bottling #${item.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`bottling/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(this.selectedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    showReturnModal: function(row) {
      this.$refs["bottling-return-modal"].show(row);
    },
    onBottlingReturnSuccess() {
      this.getData(this.selectedFilters);
    },
    showTransferModal: function(row) {
      this.$refs["bottling-transfer-modal"].show(row);
    },
    onBottlingTransferSuccess() {
      this.getData(this.selectedFilters);
    }
  },
  watch: {}
};
</script>

<style scoped></style>
