import { api } from "@/shared/api";

const bottling = {
  returnBottling(payload) {
    return api.put(`bottling/${payload.bottling_number}/return`, payload);
  },
  transferBottling(payload) {
    console.log("transfer", JSON.stringify(payload));
    return api.put(`bottling/${payload.bottling_number}/transfer`, payload);
  }
};

export default bottling;
