var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.dataset)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [+props.row['Bottles Remaining'] > 0 ? _c('button', {
          staticClass: "btn btn-warning btn-sm",
          attrs: {
            "title": "Transfer bottling"
          },
          on: {
            "click": function click($event) {
              return _vm.showTransferModal(props.row);
            }
          }
        }, [_c('strong', [_vm._v("MV")])]) : _vm._e(), !props.row['Return'] ? _c('button', {
          staticClass: "btn btn-warning btn-sm",
          attrs: {
            "title": "Bottling return "
          },
          on: {
            "click": function click($event) {
              return _vm.showReturnModal(props.row);
            }
          }
        }, [_c('strong', [_vm._v("RT")])]) : _vm._e(), _c('button', {
          staticClass: "btn btn-primary btn-sm",
          attrs: {
            "title": "View"
          },
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          attrs: {
            "title": "Edit"
          },
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            "title": "Delete"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1)])]);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add bottling"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1)], 1)], 1)], 1)], 1)]), _c('BottlingReturnModal', {
    ref: "bottling-return-modal",
    on: {
      "success": _vm.onBottlingReturnSuccess
    }
  }), _c('BottlingTransferModal', {
    ref: "bottling-transfer-modal",
    on: {
      "success": _vm.onBottlingTransferSuccess
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }